.rewards-gift-item {
    width: 302px;
}

@media (max-width: 1023px) {
    .rewards-gift-item {
        width: auto;
    }
}

.rewards-code-item {
    width: 302px;

    overflow: hidden;
}

@media (max-width: 1023px) {
    .rewards-code-item {
        width: auto;
    }
}

.rewards-modal-bottom {
    background: var(--White, #FFF);
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px -4px 6px -1px rgba(0, 0, 0, 0.10);
}

.rewards-share-toast-desktop {
    display: flex;
    position: fixed;

    width: 343px;
    height: 47px;

    top: 182px;
    right: 80px;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 14px;

    opacity: 1;
    border-radius: 100px;
    border: 1px solid var(--grey-100, #E7E7E7);
    background: var(--white, #FFF);
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);

    z-index: 50;

    transition: all 0.3s ease;
}

@media (max-width: 1023px) {
    .rewards-share-toast-desktop {
        opacity: 0;
        display: none;
    }
}

.rewards-share-toast-desktop.hidden {
    right: 0px;

    opacity: 0;

    transition: all 0.3s ease;
}

@media (max-width: 1023px) {
    .rewards-share-toast-desktop.hidden {
        display: none;
    }
}

.rewards-share-toast-mobile {
    display: flex;
    position: fixed;

    height: 47px;

    bottom: 88px;
    left: 16px;
    right: 16px;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 14px;

    opacity: 1;
    border-radius: 100px;
    border: 1px solid var(--grey-100, #E7E7E7);
    background: var(--white, #FFF);
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);

    z-index: 50;

    transition: all 0.3s ease;

    pointer-events: auto;
}

@media (min-width: 1024px) {
    .rewards-share-toast-mobile {
        display: none;
    }
}

.rewards-share-toast-mobile.hidden {
    right: 0px;

    opacity: 0;

    z-index: -1;

    transition: all 0.3s ease;

    pointer-events: none;
}

@media (min-width: 1024px) {
    .rewards-share-toast-mobile.hidden {
        display: none;
    }
}
