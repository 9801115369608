.search-mobile-topbar {
  margin-top: 82px;
}

.search-mobile-topbar.withNoti {
  margin-top: calc(82px + 45px);
}

.search-leftbar-personal {
  background: radial-gradient(
    256.41% 124.09% at 14.33% 50.19%,
    #fffcfc 0%,
    #fff4e7 52.91%,
    #f8b4b4 100%
  );
}

.search-rightbar-content-tag-pink {
  height: 32px;

  background: rgba(252, 225, 229, 0.5);
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: #f16b7c;
}

.search-rightbar-content-tag-blue {
  height: 32px;

  background: rgba(214, 221, 251, 0.5);
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: #5882ff;
}

.search-rightbar-content-tag-orange {
  height: 32px;

  background: rgba(255, 220, 198, 0.5);
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: #ffa66d;
}

.search-rightbar-content-tag-gray {
  height: 32px;

  background: #f5f5f5;
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: #4a4a4a;
}

.search-rightbar-content-comment-text {
  display: -webkit-box;
  max-width: 688px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-rightbar-content-comment-text-mobile {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-share-toast-desktop {
  display: flex;
  position: fixed;

  width: 343px;
  height: 47px;

  top: 136px;
  right: 80px;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;

  opacity: 1;
  border-radius: 100px;
  border: 1px solid var(--grey-100, #e7e7e7);
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);

  z-index: 50;

  transition: all 0.3s ease;
}

.search-share-toast-desktop.hidden {
  right: 0px;

  opacity: 0;

  transition: all 0.3s ease;
}

.search-share-toast-mobile {
  display: flex;
  position: fixed;

  height: 47px;

  bottom: 88px;
  left: 16px;
  right: 16px;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;

  opacity: 1;
  border-radius: 100px;
  border: 1px solid var(--grey-100, #e7e7e7);
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);

  z-index: 50;

  transition: all 0.3s ease;
}

.search-share-toast-mobile.hidden {
  right: 0px;

  opacity: 0;

  z-index: -1;

  transition: all 0.3s ease;
}
