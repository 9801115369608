.mobiletabbar-frame {
    height: 64px;
}

.mobiletabbar-grid-item img {
    width: 24px;
    height: 24px;

    margin-left: auto;
    margin-right: auto;
}

.mobiletabbar-grid-item span {
    margin-top: 4px;
}