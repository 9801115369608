.menubar-topbanner {
    height: 45px;
}

.menubar-topbanner-item {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
}

.menubar-topbanner-item.highlighted {
    opacity: 1;
    pointer-events: unset;
    transition: opacity 0.15s ease-in-out;
}

.menubar-normal {
    height: 105px;
}

@media (max-width: 1023px) {
    .menubar-normal {
        height: 66px;
    }
}

.menubar-normal-logo {
    width: 149px;
    height: 25px;
}

@media (max-width: 1023px) {
    .menubar-normal-logo {
        width: 133px;
        height: 22px;
    }
}

.menubar-normal-search-frame {
    margin-left: 50px;

    opacity: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.menubar-normal-search-frame.transpirant {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.menubar-normal-search-icon {
    padding-left: 24px;
}

.menubar-normal-search {
    width: 369px;
    height: 56px;

    padding-left: 64px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.menubar-normal-search-button img {
    margin-right: 8px;
}