.desktop-modal {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 24px 48px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.mobile-modal {
  position: relative;
  display: flex;
  flex-direction: column;
}
