.ranking-banner {
    height: 289px;
    
    background-position: center;
    background-size: cover;
}

@media (max-width: 1023px) {
    .ranking-banner {
        height: 201px;
        margin-bottom: 0px;
    }
}

.ranking-category {
    width: auto;
    min-width: auto;
}

@media (max-width: 1023px) {
    .ranking-category {
        min-width: 100%;
        width: max-content;
    }
}

.ranking-item {
    background: rgba(245, 245, 245, 0.50);
    transition: all 0.3s ease;
}

.ranking-item:hover {
    background: #F5F5F5;
    transition: all 0.3s ease;
}