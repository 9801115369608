.notion-app {
  min-height: 0px !important;
}

.notion-header .notion-nav-header {
  padding: 0px !important;
}

.notion-page-scroller {
  min-height: 0px !important;
}

.notion-full-page {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.notion-page-icon-hero.notion-page-icon-span {
  position: static !important;
  margin-left: 0px !important;
}

.notion-collection {
  width: 100% !important;
}

.notion-board-view.notion-board-view-size-medium {
  padding-left: 0px !important;
}

.notion-board-body {
  padding: 4px !important;
}

/* carousel */
.mini-banner-carousel .carousel.carousel-slider {
  overflow: visible !important;
}

.mobile-mini-banner-carousel .carousel.carousel-slider {
  overflow: visible !important;
}

.mobile-mini-banner-carousel .carousel.carousel-slider .slider-wrapper {
  overflow: visible !important;
}
