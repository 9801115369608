.register-popupbg {
  position: fixed;
  z-index: 61;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

@media (min-width: 1024px) {
  .register-popupbg {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.register-popup {
  background: #fff;
  width: 480px;
  min-height: 670px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sms-verify.input-field {
  width: 45px;
  height: 56px;
  border: 1px solid;
}
