.footer-frame {
    background: rgba(245, 245, 245, 0.5);
}

@media (max-width: 1023px) {
    .footer-inner {
        margin-left: 16px;
        margin-right: 16px;
    }
}

@media (max-width: 1023px) {
    .footer-column {
        margin-top: 48px;
    }

    .footer-column:first-child {
        margin-top: 0px;
    }
}

.footer-logo {
    width: 112px;
    height: 19px;
}

.footer-links-header {
    color: #111928;
}