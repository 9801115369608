@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .pause {
    animation-play-state: paused;
  }
}

html,
body,
#__next {
  scrollbar-width: none;
  background: #ffffff;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.home-banner .carousel.carousel-slider {
  overflow: visible;
}

.home-banner .carousel.carousel-slider .control-dots {
  margin: 0px;
  bottom: -36px !important;
}

@media (max-width: 1023px) {
  .home-banner .carousel.carousel-slider .control-dots {
    margin: 0px;
    bottom: -24px !important;
  }
}

.globals-centerContentsMargin {
  margin-top: 105px;
  margin-bottom: 0px;
}

@media (max-width: 1023px) {
  .globals-centerContentsMargin {
    margin-top: 66px;
    margin-bottom: 64px;
  }
}

.globals-centerContentsMargin.withNoti {
  margin-top: calc(105px + 45px);
}

@media (max-width: 1023px) {
  .globals-centerContentsMargin.withNoti {
    margin-top: calc(66px + 45px);
  }
}

.globals-centerContentsMargin.disabledTop {
  margin-top: 0px;
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
