@media (min-width: 1024px) {
    .commentPopup-container {
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.10);
    }
}

.commentPopup-addProduct {
    margin-left: auto !important;
    margin-right: 0px !important;
}

.commentPopup-textfield-title-optional {
    margin-left: auto !important;
    margin-right: 0px !important;
}