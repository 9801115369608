.index-header {
  height: 320px;
}

.index-header.withNoti {
  height: calc(320px + 45px);
}

@media (max-width: 1023px) {
  .index-header {
    height: 287px;
  }
}

@media (max-width: 1023px) {
  .index-header.withNoti {
    height: calc(287px + 45px);
  }
}

.index-header-maxWidthContainer {
  padding-top: 100px;
}

.index-header-maxWidthContainer.withNoti {
  padding-top: calc(100px + 45px);
}

@media (max-width: 1023px) {
  .index-header-maxWidthContainer {
    padding-top: 70px;
  }
}

@media (max-width: 1023px) {
  .index-header-maxWidthContainer.withNoti {
    padding-top: calc(70px + 45px);
  }
}

.index-header-captions {
  padding-top: 32px;
}

.index-header-search-background {
  position: absolute;
  top: 0px;
  height: 0px;
  width: 100%;
  height: 100%;

  z-index: 49;

  background-color: #4a4a4a;
  opacity: 0.8;

  transition: all 0.15s ease-in-out;
}

.index-header-search-background.transpirant {
  opacity: 0;

  pointer-events: none;

  transition: all 0.15s ease-in-out;
}

.index-header-search-frame {
  opacity: 1;
  transition: all 0.15s ease-in-out;
}

.index-header-search-frame.transpirant {
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}

.index-header-search-mobile-frame {
  opacity: 1;
  transition: all 0.15s ease-in-out;
}

.index-header-search-mobile-frame.transpirant {
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}

.index-header-search-mobile-icon {
  padding-left: 32px;
}

.index-header-search-mobile {
  height: 48px;

  padding-left: 48px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.index-header-search-mobile-overlay {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 32px;

  background-color: #ffffff;
}

.index-header-search-mobile-overlay-title {
  height: 48px;

  text-align: center;

  justify-content: center;
  align-items: center;
}

.index-banner-container-outer {
  overflow: hidden;
}

.index-banner-container-inner {
  width: fit-content;

  transition: margin-left 0.15s ease-in-out;
}

.index-banner-item {
  width: 419px;
  height: 236px;

  /* background: #376485; */
  border-radius: 8px;

  background-size: cover;
  background-position: center;
}

.index-banner-item-mobile {
  border-radius: 8px;

  background-size: cover;
  background-position: center;
}

.index-banner-button-frame {
  /* pointer-events: none; */
}

.index-banner-button-frame.transpirant {
  pointer-events: none;
}

.index-banner-button {
  background: #ffffff;
  border: 0.909091px solid #e7e7e7;
  box-shadow: 0px 5px 7.5px -1.25px rgba(0, 0, 0, 0.1),
    0px 2.5px 5px -2.5px rgba(0, 0, 0, 0.05);

  transition: opacity 0.15s ease-in-out;
}

.index-banner-button.transpirant {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.index-banner-overlay-left {
  /* background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%); */
  transition: opacity 0.15s ease-in-out;

  pointer-events: none;
}

.index-banner-overlay-left.transpirant {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.index-banner-overlay-right {
  /* background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%); */
  transition: opacity 0.15s ease-in-out;

  pointer-events: none;
}

.index-banner-overlay-right.transpirant {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.index-banner-pageDot {
  background: #111928;
  opacity: 0.4;
}

.index-banner-pageDot.highlighted {
  opacity: 1;
}

.index-category-outer {
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.index-category-outer::-webkit-scrollbar {
  display: none;
}

.index-category-inner {
  width: fit-content;
}

.index-category-item {
  width: max-content;

  padding-bottom: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;

  color: #4a4a4a;

  cursor: pointer;
}

@media (max-width: 1023px) {
  .index-category-item {
    padding-bottom: 8px;

    font-size: 16px;
  }
}

.index-category-item.selected {
  font-weight: 700;
  color: #111928;

  border-bottom: 3px solid #f16b7c;
}

.index-featured-tag {
  height: 26px;

  background: rgba(252, 225, 229, 0.5);
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;

  color: #f16b7c;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1023px) {
  .index-featured-tag {
    height: 20px;
    font-size: 12px;
  }
}

.index-featured-tagMore {
  height: 26px;

  background: #f5f5f5;
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;

  color: #4a4a4a;
}

@media (max-width: 1023px) {
  .index-featured-tagMore {
    height: 20px;
    font-size: 12px;
  }
}

@media (max-width: 1023px) {
  .index-featured-outer {
    overflow-x: scroll;
  }
}

/* @media (max-width: 1023px) {
    .index-featured-inner {
        max-width: max-content;
    }
} */

.index-featured-inner {
  min-width: max-content;
}

@media (max-width: 1023px) {
  .index-featured-inner {
    margin-left: 0px;
  }
}

.index-featured-item {
  width: 302px;
  height: 521px;
}

@media (max-width: 1023px) {
  .index-featured-item {
    width: 141px;
    min-height: 320px;
    height: auto;
  }
}

.index-comment-item {
  border-radius: 16px;
  background: #F5F5F5;
}

.index-comment-item-product-image {
  width: 66px !important;
  height: 66px !important;
}

.index-comment-item-review-image {
  width: 70px !important;
}