.home-banner .carousel.carousel-slider {
    height: 100%;
}

.home-comments .carousel.carousel-slider {
    height: calc(100% + 44px);
    position: initial;
    padding-bottom: 44px;
}

.carousel .slider-wrapper {
    height: 100%;
}

.home-banner .carousel.carousel-slider .control-dots {
    bottom: 12px;
}

.home-comments .carousel.carousel-slider .control-dots {
    bottom: -44px;
}

.carousel .slider {
    height: 100%;
}

.carousel .control-dots .dot {
    width: 8px !important;
    height: 8px !important;

    background: #111928 !important;
    opacity: 0.4 !important;

    box-shadow: none !important;
}

.carousel .control-dots .dot.selected {
    opacity: 1 !important;
}